import { appConfigKey } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { validarCorreo, validarEdadMinima, validarFecha, validarFechaPosterior, validarLongitud, validarMatriz, validarSelect, validarText, validarTextRuc, validarNumeroDoc } from "../../../../helpers/ValidarHelper";
import { ValidacionSolicitudObservada } from "../interfaces/solicitudObservadaInterface";
import { ValidationMemberStepOneDataInteface } from "../interfaces/validationMember";

const ValidacionStepOneHelper = {
    NewTitularData: (newTitularData: any, newIngresosTitularData: any, isTitularJuridico: boolean | null = null) => {
        const {
            Nombres, ApellidoPaterno, ApellidoMaterno, Celular,
            Correo, Domicilio, NumeroDocumento, NumeroDocumentoComplementario,
            FechaNacimiento, FechaSolicitud,
            DocumentoComplementarioId, ActividadEconomicaId,
            EstadoCivilId, GeneroId, MagnitudId, TipoDocumentoId, TipoPersonaId,
            FechaInicioActividad, RangoVentaAnualId, GiroNegocioId
        } = newTitularData;

        const {
            identCategoriaLaboral
        } = newIngresosTitularData;

        if (!validarSelect(TipoDocumentoId, 'Titular Tipo Documento')) {
            return false;
        }

        if (!validarText(NumeroDocumento, 'Titular Numero Documento')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarText(Nombres, 'Titular Nombres')) {
            return false;
        }

        if (TipoDocumentoId == appConfigKey.keyIdRUC && !validarText(Nombres, 'Titular Razón social')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarText(ApellidoPaterno, 'Titular Apellido Paterno')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarText(ApellidoMaterno, 'Titular Apellido Materno')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarSelect(EstadoCivilId, 'Titular Estado Civil')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarSelect(GeneroId, 'Titular Genero')) {
            return false;
        }

        if (!validarSelect(ActividadEconomicaId, 'Titular Actividad Económica')) {
            return false;
        }

        if (!validarSelect(MagnitudId, 'Titular Magnitud')) {
            return false;
        }

        if (!validarText(Celular, 'Titular Celular')) {
            return false;
        }

        if (!validarLongitud(Celular, 'Titular Celular', 9)) {
            return false;
        }

        if (Correo != "" && Correo != undefined && Correo != null) {
            if (!validarCorreo(Correo, 'Titular Correo')) {
                return false;
            }
        }

        if (!validarSelect(Domicilio, 'Titular Domicilio')) {
            return false;
        }

        if (isTitularJuridico && !validarSelect(RangoVentaAnualId, 'Titular Rango de Ventas Anual')) {
            return false;
        }

        if (isTitularJuridico && !validarSelect(GiroNegocioId, 'Titular Giro de Negocio')) {
            return false;
        }

        if (!validarFecha(FechaSolicitud, 'Titular Fecha Solicitud')) {
            return false;
        }

        if (!isTitularJuridico && !validarFecha(FechaNacimiento, 'Titular Fecha Nacimiento')) {
            return false;
        }

        if (!isTitularJuridico && !validarEdadMinima(FechaNacimiento, 'Titular Fecha Nacimiento')) {
            return false;
        }

        if (TipoDocumentoId == appConfigKey.keyIdRUC && !validarFecha(FechaInicioActividad, 'Titular Fecha Inicio Actividad')) {
            return false;
        }

        if (!validarSelect(TipoPersonaId, 'Titular Tipo Persona')) {
            return false;
        }

        return true;

    },
    NewIngresosTitularData: (newIngresosTitularData: any, newTitularData: any, isTitularJuridico: boolean | null = null) => {

        const {
            fechaIngresoLaboral,
            fechaHastaAnterior, fechaIngresoLaboralAnterior,
            identCategoriaLaboral, identCategoriaLaboralAnterior, identTipoMoneda,
            ingresosNetos, listaOtrosTrabajos,
            razonSocial, razonSocialAnterior,
            regimenId, regimenIdAnterior,
            ruc, rucAnterior, tieneTrabajoAnterior,
            matrizRegimen
        } = newIngresosTitularData;

        const {
            TipoDocumentoId
        } = newTitularData;

        if (!validarSelect(identCategoriaLaboral, 'Titular Datos Laborales Categoria Laboral')) {
            return false;
        }

        if (identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
            if (!validarSelect(regimenId, 'Titular Datos Laborales Regimen')) {
                return false;
            }
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarTextRuc(ruc, 'Titular Datos Laborales RUC', identCategoriaLaboral)) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarTextRuc(razonSocial, 'Titular Datos Laborales Razon Social', identCategoriaLaboral)) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
            let msgValidacion = 'Titular Datos Laborales Fecha Ingreso Laboral';
            if (identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres) {
                msgValidacion = 'Titular Datos Laborales Fecha Inicio Actividad';
            }
            if (!validarFecha(fechaIngresoLaboral, msgValidacion)) {
                return false;
            } else {
                if (!validarFechaPosterior(fechaIngresoLaboral, msgValidacion)) {
                    return false;
                }
            }
        }

        if (isTitularJuridico && !validarMatriz(matrizRegimen, 'Régimen')) {
            return false;
        }

        if (!isTitularJuridico && !validarText(ingresosNetos, 'Titular Datos Laborales Ingresos Netos')) {
            return false;
        }

        if (TipoDocumentoId != appConfigKey.keyIdRUC && !validarSelect(identTipoMoneda, 'Titular Datos Laborales Tipo Moneda')) {
            return false;
        }

        if (tieneTrabajoAnterior) {

            if (identCategoriaLaboralAnterior !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                if (!validarFecha(fechaIngresoLaboralAnterior, 'Titular Datos Laborales Anterior Fecha Ingreso Laboral')) {
                    return false;
                } else {
                    if (!validarFechaPosterior(fechaIngresoLaboralAnterior, 'Titular Datos Laborales Anterior Fecha Ingreso Laboral')) {
                        return false;
                    }
                }
            }

            if (identCategoriaLaboralAnterior !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                if (!validarFecha(fechaHastaAnterior, 'Titular Datos Laborales Anterior Fecha Hasta')) {
                    return false;
                }
            }

            if (!validarText(razonSocialAnterior, 'Titular Datos Laborales Anterior Razon Social')) {
                return false;
            }

            if (!validarText(rucAnterior, 'Titular Datos Laborales Anterior RUC')) {
                return false;
            }

            if (identCategoriaLaboralAnterior === appConfigKey.keyCodigoCategoriaLaboralTres) {
                if (!validarSelect(regimenIdAnterior, 'Titular Datos Laborales Anterior Regimen')) {
                    return false;
                }
            }

            if (!validarSelect(identCategoriaLaboralAnterior, 'Titular Datos Laborales Anterior Categoria Laboral')) {
                return false;
            }

        }

        if (listaOtrosTrabajos.length > 0) {
            for (let index = 0; index < listaOtrosTrabajos.length; index++) {

                const {
                    actividadEconomicaId, fechaIngresoLaboral,
                    identCategoriaLaboral, identTipoMoneda,
                    ingresosNetos, razonSocial,
                    regimenId, ruc
                } = listaOtrosTrabajos[index];

                if (!validarText(razonSocial, `Titular Datos Laborales Otros Razon Social`)) {
                    return false;
                }

                if (!validarText(ruc, `Titular Datos Laborales Otros ${razonSocial} RUC`)) {
                    return false;
                }

                if (identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                    if (!validarFecha(fechaIngresoLaboral, `Titular Datos Laborales Otros ${razonSocial} Fecha Ingreso Laboral`)) {
                        return false;
                    } else {
                        if (!validarFechaPosterior(fechaIngresoLaboral, `Titular Datos Laborales Otros ${razonSocial} Fecha Ingreso Laboral`)) {
                            return false;
                        }
                    }
                }

                if (!validarText(ingresosNetos, `Titular Datos Laborales Otros ${razonSocial} Ingresos Netos`)) {
                    return false;
                }

                if (!validarSelect(identTipoMoneda, `Titular Datos Laborales Otros ${razonSocial} Tipo Moneda`)) {
                    return false;
                }

                if (!validarSelect(actividadEconomicaId, `Titular Datos Laborales Otros ${razonSocial} Actividad Económica`)) {
                    return false;
                }

                if (!validarSelect(identCategoriaLaboral, `Titular Datos Laborales Otros ${razonSocial} Categoría Laboral`)) {
                    return false;
                }

                if (identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
                    if (!validarSelect(regimenId, `Titular Datos Laborales Otros ${razonSocial} Regimen`)) {
                        return false;
                    }
                }

            }
        }

        return true;

    },
    NewConyugeData: (newConyugeData: any, newTitularData: any) => {
        const {
            NumeroDocumento, Nombres, ApellidoPaterno, ApellidoMaterno,
            FechaNacimiento,
            GeneroId, ActividadEconomicaId, MagnitudId, TipoDocumentoId
        } = newConyugeData;

        const {NumeroDocumento: NumeroDocumentoTitular} = newTitularData;

        if (!validarText(NumeroDocumento, 'Conyuge Número Documento')) {
            return false;
        }

        debugger;
        if (!validarNumeroDoc(NumeroDocumentoTitular, NumeroDocumento)) {
            return false;
        }

        if (!validarText(Nombres, 'Conyuge Nombres')) {
            return false;
        }

        if (!validarText(ApellidoPaterno, 'Conyuge Apellido Paterno')) {
            return false;
        }

        if (!validarText(ApellidoMaterno, 'Conyuge Apellido Materno')) {
            return false;
        }

        if (!validarFecha(FechaNacimiento, 'Conyuge Fecha Nacimiento')) {
            return false;
        }

        if (!validarEdadMinima(FechaNacimiento, 'Conyuge Fecha Nacimiento')) {
            return false;
        }

        if (!validarSelect(GeneroId, 'Conyuge Genero')) {
            return false;
        }

        if (!validarSelect(ActividadEconomicaId, 'Conyuge Actividad Economía')) {
            return false;
        }

        if (!validarSelect(MagnitudId, 'Conyuge Magnitud')) {
            return false;
        }

        if (!validarSelect(TipoDocumentoId, 'Conyuge Tipo Documento')) {
            return false;
        }

        return true;

    },
    NewIngresosConyugeData: (newIngresosConyugeData: any) => {
        const {
            fechaHastaAnterior, fechaIngresoLaboral, fechaIngresoLaboralAnterior,
            identCategoriaLaboral, identCategoriaLaboralAnterior, identTipoMoneda,
            ingresosNetos,
            listaOtrosTrabajos,
            razonSocial, razonSocialAnterior,
            regimenId, regimenIdAnterior,
            ruc, rucAnterior, tieneTrabajoAnterior
        } = newIngresosConyugeData;

        if (identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {

            if (identCategoriaLaboral !== 0) {

                if (!validarTextRuc(ruc, 'Conyuge Datos Laborales RUC', identCategoriaLaboral)) {
                    return false;
                }

                if (!validarTextRuc(razonSocial, 'Conyuge Datos Laborales Razon Social', identCategoriaLaboral)) {
                    return false;
                }

            }

            if (!validarText(ingresosNetos, 'Conyuge Datos Laborales Ingresos Netos')) {
                return false;
            }

            if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado !== identCategoriaLaboral) {
                if (!validarFecha(fechaIngresoLaboral, 'Conyuge Datos Laborales Fecha Ingreso Laboral')) {
                    return false;
                } else {
                    if (!validarFechaPosterior(fechaIngresoLaboral, 'Conyuge Datos Laborales Fecha Ingreso Laboral')) {
                        return false;
                    }
                }
            }

            if (!validarSelect(identTipoMoneda, 'Conyuge Datos Laborales Tipo Moneda')) {
                return false;
            }

            if (identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
                if (!validarSelect(regimenId, 'Conyuge Datos Laborales Regimen')) {
                    return false;
                }
            }

            if (tieneTrabajoAnterior) {

                if (!validarText(rucAnterior, 'Conyuge Datos Laborales Anterior RUC')) {
                    return false;
                }

                if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado !== identCategoriaLaboralAnterior) {
                    if (!validarFecha(fechaIngresoLaboralAnterior, 'Conyuge Datos Laborales Anterior Fecha Ingreso Laboral')) {
                        return false;
                    } else {
                        if (!validarFechaPosterior(fechaIngresoLaboralAnterior, 'Conyuge Datos Laborales Anterior Fecha Ingreso Laboral')) {
                            return false;
                        }
                    }
                }

                if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado !== identCategoriaLaboralAnterior) {
                    if (!validarFecha(fechaHastaAnterior, 'Conyuge Datos Laborales Anterior Fecha Hasta Laboral')) {
                        return false;
                    }
                }

                if (!validarText(razonSocialAnterior, 'Conyuge Datos Laborales Anterior Razon Social')) {
                    return false;
                }

                if (!validarSelect(identCategoriaLaboralAnterior, 'Conyuge Datos Laborales Anterior Categoria Laboral')) {
                    return false;
                }

                if (identCategoriaLaboralAnterior === appConfigKey.keyCodigoCategoriaLaboralTres) {
                    if (!validarSelect(regimenIdAnterior, 'Conyuge Datos Laborales Anterior Regimen')) {
                        return false;
                    }
                }
            }

            if (listaOtrosTrabajos.length > 0) {
                for (let index = 0; index < listaOtrosTrabajos.length; index++) {

                    const {
                        actividadEconomicaId, fechaIngresoLaboral,
                        identCategoriaLaboral, identTipoMoneda, ingresosNetos, razonSocial, regimenId, ruc
                    } = listaOtrosTrabajos[index];

                    if (!validarText(razonSocial, 'Conyuge Datos Laborales Otros Razon Social')) {
                        return false;
                    }

                    if (!validarText(ruc, `Conyuge Datos Laborales Otros ${razonSocial} RUC`)) {
                        return false;
                    }

                    if (!validarText(ingresosNetos, `Conyuge Datos Laborales Otros ${razonSocial} Ingresos Netos`)) {
                        return false;
                    }

                    if (identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                        if (!validarFecha(fechaIngresoLaboral, `Conyuge Datos Laborales Otros ${razonSocial} Fecha Ingreso Laboral`)) {
                            return false;
                        } else {
                            if (!validarFechaPosterior(fechaIngresoLaboral, `Conyuge Datos Laborales Otros ${razonSocial} Fecha Ingreso Laboral`)) {
                                return false;
                            }
                        }
                    }

                    if (!validarSelect(actividadEconomicaId, `Conyuge Datos Laborales Otros ${razonSocial} Actividad Económica`)) {
                        return false;
                    }

                    if (!validarSelect(identTipoMoneda, `Conyuge Datos Laborales Otros ${razonSocial} Tipo Moneda`)) {
                        return false;
                    }

                    if (!validarSelect(identCategoriaLaboral, `Conyuge Datos Laborales Otros ${razonSocial} Categoria Laboral`)) {
                        return false;
                    }

                    if (identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
                        if (!validarSelect(regimenId, `Conyuge Datos Laborales Otros ${razonSocial} Regimen`)) {
                            return false;
                        }
                    }

                }
            }

        }

        return true;

    },
    NewFiadorData: (newFiadorData: any) => {
        const {
            TipoDocumentoId, NumeroDocumento,
            Nombres, ApellidoMaterno, ApellidoPaterno,
            FechaNacimiento,
            EstadoCivilId, GeneroId, NacionalidadId,
            PaisRecidenciaId, ProfesionId,
            Conyuge
        } = newFiadorData;

        if (!validarText(NumeroDocumento, 'Fiador Numero Documento')) {
            return false;
        }

        if (!validarSelect(TipoDocumentoId, `Fiador ${NumeroDocumento} Tipo Documento`)) {
            return false;
        }

        if (!validarText(Nombres, `Fiador ${NumeroDocumento} Nombres`)) {
            return false;
        }

        if (!validarText(ApellidoPaterno, `Fiador ${NumeroDocumento} Apellido Paterno`)) {
            return false;
        }

        if (!validarText(ApellidoMaterno, `Fiador ${NumeroDocumento} Materno`)) {
            return false;
        }

        if (!validarFecha(FechaNacimiento, `Fiador ${NumeroDocumento} Fecha Nacimiento`)) {
            return false;
        }

        if (!validarSelect(EstadoCivilId, `Fiador ${NumeroDocumento} Estado Civil`)) {
            return false;
        }

        if (!validarSelect(GeneroId, `Fiador ${NumeroDocumento} Genero`)) {
            return false;
        }

        if (!validarSelect(NacionalidadId, `Fiador ${NumeroDocumento} Nacionalidad`)) {
            return false;
        }

        if (!validarSelect(PaisRecidenciaId, `Fiador ${NumeroDocumento} Pais Recidencia`)) {
            return false;
        }

        if (!validarSelect(ProfesionId, `Fiador ${NumeroDocumento} Profesion`)) {
            return false;
        }

        if (EstadoCivilId === appConfigKey.keyCodigoEstadoCivilCasado) {
            const {
                TipoDocumentoId, NumeroDocumento,
                Nombres, ApellidoMaterno, ApellidoPaterno,
                FechaNacimiento,
                GeneroId, NacionalidadId, PaisRecidenciaId, ProfesionId
            } = Conyuge;

            if (!validarText(NumeroDocumento, `Fiador Conyuge Numero Documento`)) {
                return false;
            }

            if (!validarSelect(TipoDocumentoId, `Fiador Conyuge ${NumeroDocumento} Tipo Documento`)) {
                return false;
            }

            if (!validarText(Nombres, `Fiador Conyuge ${NumeroDocumento} Nombres`)) {
                return false;
            }

            if (!validarText(ApellidoPaterno, `Fiador Conyuge ${NumeroDocumento} Apellido Paterno`)) {
                return false;
            }

            if (!validarText(ApellidoMaterno, `Fiador Conyuge ${NumeroDocumento} Materno`)) {
                return false;
            }

            if (!validarFecha(FechaNacimiento, `Fiador Conyuge ${NumeroDocumento} Fecha Nacimiento`)) {
                return false;
            }

            if (!validarSelect(GeneroId, `Fiador Conyuge ${NumeroDocumento} Genero`)) {
                return false;
            }

            if (!validarSelect(NacionalidadId, `Fiador Conyuge ${NumeroDocumento} Nacionalidad`)) {
                return false;
            }

            if (!validarSelect(PaisRecidenciaId, `Fiador Conyuge ${NumeroDocumento} Pais Recidencia`)) {
                return false;
            }

            if (!validarSelect(ProfesionId, `Fiador Conyuge ${NumeroDocumento} Profesion`)) {
                return false;
            }
        }

        return true;
    },
    NewPersonaCompraParaData: (newPersonaCompraParaData: any) => {
        const {
            NumeroDocumento, Nombres, ApellidoPaterno, ApellidoMaterno, RazonSocial,
            FechaNacimiento,
            GeneroId, EstadoCivilId, NacionalidadId,
            PaisRecidenciaId, TipoDocumentoId
        } = newPersonaCompraParaData;

        if (NumeroDocumento === undefined || NumeroDocumento === "" || NumeroDocumento === null) {
            return true;
        }

        if (!validarText(NumeroDocumento, 'Persona Compara Para Numero Documento')) {
            return false;
        }

        if (!validarSelect(TipoDocumentoId, 'Persona Compara Para Tipo Documento')) {
            return false;
        }

        if (TipoDocumentoId === appConfigKey.keyIdRUC) {
            if (!validarText(RazonSocial, 'Persona Compara Para Razón Social')) {
                return false;
            }

            if (!validarFecha(FechaNacimiento, 'Persona Compara Para Fecha Constitución')) {
                return false;
            }

        } else {
            if (!validarText(Nombres, 'Persona Compara Para Nombres')) {
                return false;
            }

            if (!validarText(ApellidoPaterno, 'Persona Compara Para Apellido Paterno')) {
                return false;
            }

            if (!validarText(ApellidoMaterno, 'Persona Compara Para Apellido Materno')) {
                return false;
            }

            if (!validarFecha(FechaNacimiento, 'Persona Compara Para Fecha Nacimiento')) {
                return false;
            }

            if (!validarSelect(GeneroId, 'Persona Compara Para Genero')) {
                return false;
            }

            if (!validarSelect(EstadoCivilId, 'Persona Compara Para Estado Civil')) {
                return false;
            }

            if (!validarSelect(NacionalidadId, 'Persona Compara Para Nacionalidad')) {
                return false;
            }

            if (!validarSelect(PaisRecidenciaId, 'Persona Compara Para Pais Recidencia')) {
                return false;
            }
        }

        return true;
    },
    NewPersonaCompraParaConyugeData: (newPersonaCompraParaConyugeData: any) => {
        const {
            NumeroDocumento, Nombres, ApellidoPaterno, ApellidoMaterno,
            FechaNacimiento,
            GeneroId, MagnitudId, ActividadEconomicaId, TipoDocumentoId
        } = newPersonaCompraParaConyugeData;


        if (NumeroDocumento === undefined || NumeroDocumento === "" || NumeroDocumento === null) {
            return true;
        }

        if (!validarText(NumeroDocumento, 'Persona Compara Para Conyuge Numero Documento')) {
            return false;
        }

        if (!validarText(Nombres, 'Persona Compara Para Conyuge Nombres')) {
            return false;
        }

        if (!validarText(ApellidoPaterno, 'Persona Compara Para Conyuge Apellido Paterno')) {
            return false;
        }

        if (!validarText(ApellidoMaterno, 'Persona Compara Para Conyuge Apellido Materno')) {
            return false;
        }

        if (!validarFecha(FechaNacimiento, 'Persona Compara Para Conyuge Fecha Nacimiento')) {
            return false;
        }

        if (!validarSelect(GeneroId, 'Persona Compara Para Conyuge Genero')) {
            return false;
        }

        if (!validarSelect(MagnitudId, 'Persona Compara Para Conyuge Magnitud')) {
            return false;
        }

        if (!validarSelect(ActividadEconomicaId, 'Persona Compara Para Conyuge Actividad Económica')) {
            return false;
        }

        if (!validarSelect(TipoDocumentoId, 'Persona Compara Para Conyuge Tipo Documento')) {
            return false;
        }

        return true;
    },
    NewObservacionData: (newObservacionData: any) => {
        const { Observaciones } = newObservacionData;

        if (!validarText(Observaciones, 'Observaciones')) {
            return false;
        }

        return true;
    },

    NewBlackListGesintel: (BlackListGesintel: any): ValidacionSolicitudObservada | null => {
        const { blackListGesintel } = BlackListGesintel;
        let entityBlackListGesintel: ValidacionSolicitudObservada | null = null;
        let detalle: string[] = [];

        if (blackListGesintel && blackListGesintel.length > 0) {

            //Obtener detalle de observación por miembro
            blackListGesintel.forEach((member: any) => {
                let tipoRelacionDescription = "";
                let observacionMensaje = "";

                if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionTitular) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionTitularDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEmpresa) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionEmpresaDescripcion2} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiador) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiadorConyuge) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorConyugeDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraPara) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraParaConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEjecutivo) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionEjecutivoDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConcesionario) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConcesionarioDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionVendedor) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionVendedorDescripcion;
                }

                if (member.bantotalObservado && member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa} / ${appConfigKey.keyMsjBaseGesintel}`
                } else if (member.bantotalObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa}`
                } else if (member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseGesintel}`
                }

                detalle.push(`${tipoRelacionDescription} ${observacionMensaje}`)
            });

            if ((blackListGesintel.filter((x: any) => x.esObservadoPlaftYRiesgo).length > 0) ||
                (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0 &&
                    blackListGesintel.filter((x: any) => x.esObservadoPlaft).length > 0)) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT y RIESGOS'),
                    detalle: detalle,
                    observadoPlaft: true,
                    observadoRiesgo: true
                }

            } else if (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'RIESGOS'),
                    detalle: detalle,
                    observadoPlaft: false,
                    observadoRiesgo: true,
                }

            } else {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT'),
                    detalle: detalle,
                    observadoPlaft: true,
                    observadoRiesgo: false,
                }
            }
        }

        return entityBlackListGesintel;
    },


    NewModelicaData: (response: any) => {
        let mensajeModelica = "";

        if (!response) {
            mensajeModelica = 'Error en la consulta del Motor';
            return mensajeModelica;
        }

        const { data } = response;

        if (!data?.isValid) {
            mensajeModelica = 'Error en la consulta del Motor';
            return mensajeModelica;
        }

        const responseModelica = JSON.parse(data.content);
        if (!responseModelica.response?.Politica_Creditos) {
            mensajeModelica = responseModelica.response?.error.message;
            return mensajeModelica;
        }

        return mensajeModelica;
    },

    ModelicaCMA: (response: any) => {
        let mensajeModelica;

        let jsonResponseModelica = JSON.parse(response?.data?.content);

        let detalleRechazo = jsonResponseModelica?.response?.Politica_Creditos?.motivo_Rechazo?.Detalle;
        if (detalleRechazo && detalleRechazo?.length > 0) {
            //CMA_0010: Base Negativa de Riesgos
            //RCH_0001: UPLA - PEP
            detalleRechazo = detalleRechazo.filter((r: any) => (r.codigo_CMA != "CMA_0010") && (r.codigo_CMA != "RCH_0001")
            );
        }
        if (detalleRechazo && detalleRechazo?.length > 0) {

            detalleRechazo.unshift({
                codigo_CMA: '',
                descripcion: appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoCMA),
                interviniente: ''
            });

            mensajeModelica = detalleRechazo?.map((det: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {(index > 0 ? '- Revise ' : '') + det?.descripcion + (det?.interviniente ? ' del ' + det?.interviniente : '')}
                </div>
            ));
        }

        return mensajeModelica;
    },

    GetMembersStepOneData: (
        solicitudData: any,
        newTitularData: any,
        newConyugeData: any,
        newIngresosTitularData: any,
        newIngresosConyugeData: any,
        newPersonaCompraParaData: any,
        newPersonaCompraParaConyugeData: any,
        newFiadorData: any
    ) => {
        let validateMembers: ValidationMemberStepOneDataInteface[] = []
        let IdSolicitud = solicitudData?.idenT_SOLICITUD;

        if (newTitularData) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionTitular,
                TipoDocumento: newTitularData.TipoDocumentoId,
                NumeroDocumento: newTitularData.NumeroDocumento
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (newConyugeData && newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionConyuge,
                TipoDocumento: newConyugeData.TipoDocumentoId,
                NumeroDocumento: newConyugeData.NumeroDocumento
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (newPersonaCompraParaData && GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '') {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionCompraPara,
                TipoDocumento: newPersonaCompraParaData.TipoDocumentoId,
                NumeroDocumento: newPersonaCompraParaData.NumeroDocumento
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado &&
            GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '') {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionCompraParaConyuge,
                TipoDocumento: newPersonaCompraParaConyugeData.TipoDocumentoId,
                NumeroDocumento: newPersonaCompraParaConyugeData.NumeroDocumento
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (newFiadorData && newFiadorData.ListaFiadores && newFiadorData.ListaFiadores.length > 0) {
            for (let rowFiador of newFiadorData.ListaFiadores) {
                if (rowFiador) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionFiador,
                        TipoDocumento: rowFiador.TipoDocumentoId,
                        NumeroDocumento: rowFiador.NumeroDocumento
                    } as ValidationMemberStepOneDataInteface
                    validateMembers.push(member);
                    if (rowFiador.Conyuge && rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
                        let member = {
                            IdSolicitud: IdSolicitud,
                            TipoRelacionId: appConfigKey.KeyIdTipoRelacionFiadorConyuge,
                            TipoDocumento: rowFiador.Conyuge.TipoDocumentoId,
                            NumeroDocumento: rowFiador.Conyuge.NumeroDocumento
                        } as ValidationMemberStepOneDataInteface
                        validateMembers.push(member);
                    }
                }
            }
        }

        if (newIngresosTitularData) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: newIngresosTitularData.ruc
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);

            // if (GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior)) {
            //     let member = {
            //         IdSolicitud: IdSolicitud,
            //         TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
            //         TipoDocumento: appConfigKey.keyIdRUC.toString(),
            //         NumeroDocumento: newIngresosTitularData.rucAnterior
            //     } as ValidationMemberStepOneDataInteface
            //     validateMembers.push(member);
            // }

            if (newIngresosTitularData.listaOtrosTrabajos && newIngresosTitularData.listaOtrosTrabajos.length > 0) {
                for (let rowOtroTrabajo of newIngresosTitularData.listaOtrosTrabajos) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.ruc
                    } as ValidationMemberStepOneDataInteface
                    validateMembers.push(member);
                }
            }
        }

        if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado &&
            newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: newIngresosConyugeData.ruc
            } as ValidationMemberStepOneDataInteface
            validateMembers.push(member);

            // if (GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior)) {
            //     let member = {
            //         IdSolicitud: IdSolicitud,
            //         TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
            //         TipoDocumento: appConfigKey.keyIdRUC.toString(),
            //         NumeroDocumento: newIngresosConyugeData.rucAnterior
            //     } as ValidationMemberStepOneDataInteface
            //     validateMembers.push(member);
            // }

            if (newIngresosConyugeData.listaOtrosTrabajos && newIngresosConyugeData.listaOtrosTrabajos.length > 0) {
                for (let rowOtroTrabajo of newIngresosConyugeData.listaOtrosTrabajos) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.ruc
                    } as ValidationMemberStepOneDataInteface
                    validateMembers.push(member);
                }
            }

        }

        //Remover miembros repetidos
        const claves = new Set<string>();
        validateMembers = validateMembers.filter(m => {
            const clave = `${m.IdPersona}-${m.TipoRelacionId}-${m.TipoDocumento}-${m.NumeroDocumento}`;
            if (!claves.has(clave)) {
                claves.add(clave);
                return true;
            }
            return false;
        });

        return validateMembers;
    }


};

export default ValidacionStepOneHelper;