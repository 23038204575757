import { appConfigGenero, appConfigKey } from "../../config/Config";
import { ConvertidorTablaHelper } from "../modules/1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";
import TimeHelper from "./TimeHelper";

const GeneralHelper = {

    CalcularAnulizado: (ingresoNeto: any) => {
        let tempIngresoNeto: any = GeneralHelper.QuitarComasAMiles(String(ingresoNeto));
        tempIngresoNeto = Number(tempIngresoNeto);
        const calculo = (tempIngresoNeto * 14) / 12;
        return GeneralHelper.AgregarComasAMiles(calculo.toFixed(2));
    },

    QuitarComasAMiles: (numero: any) => {
        let quitaComasAMiles: string = "";
        try {
            if (typeof numero === "number") {
                quitaComasAMiles = numero.toString();
            } else if (typeof numero === "string") {
                quitaComasAMiles = numero.replace(/,/g, '');
            }
        } catch {
            quitaComasAMiles = '';
        }
        return quitaComasAMiles;
    },

    QuitarPorcentaje: (numero: any) => {
        let quitaPorcentaje: string = "";
        try {
            if (typeof numero === "number") {
                quitaPorcentaje = numero.toString();
            } else if (typeof numero === "string") {
                quitaPorcentaje = numero.replace(/[^\d.]/g, '');
            }
        } catch {
            quitaPorcentaje = '';
        }
        return quitaPorcentaje;
    },

    AgregarComasAMiles: (numero: any) => {
        let retornaComasAMiles: string = "";
        try {
            if (typeof numero === "number") {
                retornaComasAMiles = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else if (typeof numero === "string") {
                retornaComasAMiles = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        } catch {
            retornaComasAMiles = '';
        }
        return retornaComasAMiles;
    },
    AgregarComasAMilesConDecimales: (numero: any, cantDecimales: number) => {
        let retornaComasAMiles: string = "";
        try {
            if (typeof numero === "number") {
                retornaComasAMiles = numero.toFixed(cantDecimales).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else if (typeof numero === "string") {
                retornaComasAMiles = Number(numero).toFixed(cantDecimales).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        } catch {
            retornaComasAMiles = '';
        }
        return retornaComasAMiles;
    },

    ValidarLongitud: (text: string, campo: string, longitud: number) => {
        if (text === undefined || text === null || text.length != longitud) {
            return [false, `El campo ${campo} debe tener ${longitud} dígitos.`];
        }
        return [true, ''];
    },

    ValidarNroDocumento: (primerDoc: string, segundoDoc: string) => {
        if (primerDoc.trim() === segundoDoc.trim()) {
            return[false, `El documento del titular es el mismo que del cónyugue.`];
        }
        return[true, '']
    },

    ValidarTextRequired: (text: string, campo: string) => {
        if (text === undefined || text === "" || text === null) {
            return [false, `El campo ${campo} es requerido.`];
        }
        return [true, ''];
    },

    ValidarFechaRequired: (text: string, campo: string) => {
        if (text === "Invalid Date" || text === undefined || text === "" || text === null) {
            return [false, `El campo ${campo} es requerido.`];
        }
        return [true, ''];
    },

    ValidarSelectRequired: (value: any, campo: string) => {
        if (value == 0) {
            return [false, `El campo ${campo} es requerido.`];
        }
        return [true, ''];
    },

    ValidarCorreo(correo: string, campo: string) {
        const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!expresionRegular.test(correo)) {
            return [false, `El campo ${campo} no tiene el formato adecuado.`]
        }
        return [true, ''];
    },

    /**
     * string format: yyyy-mm-dd => 2000-01-01
     * @param fecha 
     */
    ValidarEdadMinima(fecha: string | Date, campo: string, edadMin: number = 18) {
        let compararDate: Date;
        if (typeof fecha === 'string') {
            try {
                const [anio, mes, dia] = fecha.split('-').map(Number);
                compararDate = new Date(anio, mes - 1, dia);
            } catch (error) {
                return [false, `El campo ${campo} no tiene el formato adecuado.`]
            }
        } else {
            compararDate = fecha;
        }

        const actualDate = new Date();

        // Calcular la edad restando el año actual menos el año de nacimiento
        let edad = actualDate.getFullYear() - compararDate.getFullYear();

        // Ajustar la edad si aún no ha pasado el cumpleaños de este año
        if (compararDate.getMonth() > actualDate.getMonth() ||
            (compararDate.getMonth() === actualDate.getMonth() && compararDate.getDate() > actualDate.getDate())) {
            edad--;
        }

        if (edad < edadMin) {
            return [false, `El campo ${campo} no tiene la edad mínima.`]
        }

        return [true, ''];
    },

    ObtenerFechaActual() {
        const fechaActual = new Date().toISOString();
        return fechaActual;
    },

    ObtenerDepartamentoId(ubigeoId: number) {
        let DepartamentoId: number = 0;
        if (ubigeoId > 0 && ubigeoId.toString().length >= 5) {
            DepartamentoId = Math.floor(ubigeoId / 10000);
        }
        return DepartamentoId;
    },

    ObtenerProvinciaId(ubigeoId: number) {
        let ProvinciaId: number = 0;
        if (ubigeoId > 0 && ubigeoId.toString().length >= 5) {
            ProvinciaId = Math.floor(ubigeoId / 100);
        }
        return ProvinciaId;
    },

    ParseString(data: any) {
        let retornaString: string = "";
        try {
            retornaString = data === undefined || data == null ? "" : data.toString().trim();
        } catch {
            retornaString = "";
        }
        return retornaString;
    },

    ObtenerStringNullable(data: any): string | null {
        let resultado: string | null = null;
        try {
            if (!data) {
                return resultado;
            }
            if (typeof data == 'string' && data.trim() === '') {
                return resultado;
            }
            resultado = data;
        } catch {
        }
        return resultado;
    },

    ParseGeneroString(data: any, valueDefault: string = "") {
        let retornaString: string = valueDefault;
        try {
            retornaString = data === undefined || data == null ? valueDefault
                : data == 0 ? valueDefault
                    : data.toString().trim();
        } catch {
            retornaString = valueDefault;
        }
        return retornaString;
    },

    ParseDateString(tipoDate: string, dateString: any): any {
        let parts: any = [];
        let day: number = 0;
        let month: number = 0;
        let year: number = 0;
        try {

            if (tipoDate === 'DD/MM/YYYY') {
                parts = dateString.split('/');
                day = parseInt(parts[0], 10);
                month = parseInt(parts[1], 10) - 1;
                year = parseInt(parts[2], 10);
            }
            if (tipoDate === 'YYYY-MM-DD') {
                parts = dateString.split('-');
                year = parseInt(parts[0]);
                month = parseInt(parts[1]) - 1;
                day = parseInt(parts[2]);
            }
            return (new Date(year, month, day)).toISOString();
        } catch {
            return null;
        }
    },

    ParseNumber(data: any) {
        let retornaNumber: number = 0;
        try {
            retornaNumber = (data === undefined || data == null) ? 0 : Number(data.toString().replace(/,/g, ''));
        } catch {
            retornaNumber = 0;
        }
        return retornaNumber;
    },

    ParseDecimal(input: any, decimals: number = 2): number {
        try {
            if (input === null ||
                input === undefined ||
                (typeof input === 'string' && input.trim() === '') ||
                isNaN(Number(input))) {
                return 0;
            }

            let num = Number(input);

            const factor = Math.pow(10, decimals);
            return Math.round(num * factor) / factor;
        } catch {
            return 0;
        }
    },

    ParseTwoDecimal(input: any): number {
        return GeneralHelper.ParseDecimal(input, 2);
    },

    ParseBoolean(data: any) {
        let retornaBoolean: boolean = false;
        try {
            retornaBoolean = data === undefined || data == null ? false : Boolean(data);
        } catch {
            retornaBoolean = false;
        }
        return retornaBoolean;
    },

    NumeroConCommas(numero: any): string {
        let retornaNumeroConCommas = '';
        try {
            if (typeof numero === "number") {
                retornaNumeroConCommas = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else if (typeof numero === "string") {
                retornaNumeroConCommas = numero.trim().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        } catch {
            retornaNumeroConCommas = '';
        }
        return retornaNumeroConCommas
    },

    EsObjetoNullOrUndefined(data: any) {
        if (data === undefined || data == null) {
            return true;
        }
        return false;
    },

    ObtenerValorPorDefecto(valor: any, valorDefecto: any) {
        if (valor === null ||
            valor === undefined ||
            (typeof valor === 'string' && valor.trim() === '') ||
            Number.isNaN(valor) ||
            valor == '0'
        ) {
            return valorDefecto;
        }
        return valor;
    },

    ObtenerGeneroPorDefecto(valor: any) {
        return GeneralHelper.ObtenerValorPorDefecto(valor, appConfigGenero.CODIGO_MASCULINO);
    },

    ObtenerMontoAnualizado(numero: any): number {
        let retornaNumber: number = 0;
        try {
            if (typeof numero === "number") {
                retornaNumber = Number(((numero * 14) / 12).toFixed(2));
            } else if (typeof numero === "string") {
                retornaNumber = Number(GeneralHelper.QuitarComasAMiles(numero));
                retornaNumber = Number(((retornaNumber * 14) / 12).toFixed(2));
            }
        } catch {
            retornaNumber = 0;
        }
        return retornaNumber;
    },

    ClonarObject(obj: any): any {

        if (obj === null || typeof obj !== "object") {
            return obj;
        }

        if (Array.isArray(obj)) {
            let clonedArray = [];
            for (let i = 0; i < obj.length; i++) {
                clonedArray[i] = GeneralHelper.ClonarObject(obj[i]);
            }
            return clonedArray;
        }

        let clonedObj: any = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                clonedObj[key] = GeneralHelper.ClonarObject(obj[key]);
            }
        }
        return clonedObj;
    },

    ObtenerExceptionError(response: any, erroMsgDefault: string | undefined = undefined) {
        let status = response?.status;
        let data = response?.data;

        if (status === 400) {
            if (data?.exceptions?.length > 0) {
                let errors = data?.exceptions[0].description?.split('\n');
                if (errors?.length > 0) {
                    return errors[0];
                }
            } else {
                return erroMsgDefault;
            }
        } else if (status === 200) {
            if (data?.isValid === false || data?.exceptions?.length > 0) {
                if (data?.exceptions?.length > 0) {
                    let errors = data?.exceptions[0].description?.split('\n');
                    if (errors?.length > 0) {
                        return errors[0];
                    }
                }
                return erroMsgDefault;
            }
        } else {
            return erroMsgDefault;
        }
    },

    ObtenerMontoNumerico(texto: string): number | null {
        try {
            const regex = /-?\d{1,3}(,\d{3})*(\.\d+)?/;
            const match = texto.match(regex)?.[0].replace(/,/g, '');
            return match ? parseFloat(match) : null;
        } catch (error) {
            return null;
        }
    },

    SepararNombreCompleto(nombreCompleto: string) {
        try {
            const regex = /^(?<apellidoPaterno>\S+)\s+(?<apellidoMaterno>\S+)\s+(?<nombres>.+)$/;
            const match = nombreCompleto.match(regex);

            if (match && match.groups) {
                const { apellidoPaterno, apellidoMaterno, nombres } = match.groups;
                return { apellidoPaterno, apellidoMaterno, nombres };
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    },

    ConcatWS(separator: string, ...args: (string | undefined | null)[]): string {
        return args.filter(arg => arg !== undefined && arg !== null).join(separator);
    },

    /**
     * 
     * @param operand formato 'yyyy-mm-dd'
     * @returns Date
     */
    ObtenerTodayDate(operand: string): Date {
        if (operand === "today()") {
            return new Date();
        } else {
            return new Date(operand + "T00:00:00-05:00");
        }
    },

    CompararTodayDate(leftDate: Date, rightDate: Date, operator: string): boolean {
        switch (operator) {
            case "<":
                return leftDate < rightDate;
            case "<=":
                return leftDate <= rightDate;
            case ">":
                return leftDate > rightDate;
            case ">=":
                return leftDate >= rightDate;
            case "=":
                return leftDate.getTime() === rightDate.getTime();
            default:
                throw new Error("Operador no válido");
        }
    },

    ObtenerOperadorText(operator: string): string {
        switch (operator) {
            case "<":
                return "menor";
            case "<=":
                return "menor igual";
            case ">":
                return "mayor";
            case ">=":
                return "mayor igual";
            case "=":
                return "igual";
            default:
                throw new Error("Operador no válido");
        }
    },

    ValidarMatrizRequired: (matriz: any, campo: string) => {
        if (!matriz || !matriz?.matrizPerfil) {
            return [false, `La Matriz ${campo} es requerido.`];
        }

        for (let formatoIndex = 0; formatoIndex < matriz.matrizPerfil.length; formatoIndex++) {
            const formato = matriz.matrizPerfil[formatoIndex];

            for (let tablaIndex = 0; tablaIndex < formato.tablas.length; tablaIndex++) {
                const tabla = formato.tablas[tablaIndex];

                for (let colIndex = 0; colIndex < tabla.columnas.length; colIndex++) {
                    const col = tabla.columnas[colIndex];

                    for (let rowIndex = 0; rowIndex < tabla.filas.length; rowIndex++) {
                        const row = tabla.filas[rowIndex];

                        if (colIndex >= 0 && colIndex < row.fila.length && col.visible) {
                            const fila = row.fila[colIndex];
                            if (fila.validacion) {
                                let formula = fila.validacion;
                                formula = ConvertidorTablaHelper.RemplazarReferenciasCeldas(formula, tabla.filas);
                                if (fila.componente === 'input-date') {
                                    try {
                                        const regex = /(\d{4}-\d{2}-\d{2}|today\(\))\s*([<>=]+)\s*(\d{4}-\d{2}-\d{2}|today\(\))/;
                                        const match = formula.match(regex);
                                        if (!match) {
                                            return [false, `El campo ${tabla.titulo} - ${row.fila[0].label} - ${col.label} es requerido.`];
                                        }

                                        const [_, leftOperand, operator, rightOperand] = match;
                                        const leftDate = GeneralHelper.ObtenerTodayDate(leftOperand);
                                        const rightDate = GeneralHelper.ObtenerTodayDate(rightOperand);
                                        const result = GeneralHelper.CompararTodayDate(leftDate, rightDate, operator);

                                        const operatorFormat = GeneralHelper.ObtenerOperadorText(operator);
                                        const rightDateFormat = TimeHelper.ObtenerFormatoFechaPeru(rightDate);

                                        if (!result) {
                                            return [false, `El campo ${tabla.titulo} - ${row.fila[0].label} - ${col.label} no es ${operatorFormat} que ${rightDateFormat}`];
                                        }

                                    } catch (error) {
                                        console.warn(`Falló procesamiento de fecha:${formula}`);
                                        return [false, `Falló la verificación de fecha ${tabla.titulo} - ${row.fila[0].label} - ${col.label} ${campo}.`];
                                    }
                                } else {
                                    try {
                                        const result = ConvertidorTablaHelper.EvaluarFormula(formula);
                                        if (!result) {
                                            return [false, `El campo ${tabla.titulo} - ${row.fila[0].label} - ${col.label} es requerido.`];
                                        }
                                    } catch (error) {
                                        console.warn(`Falló procesamiento de formula:${formula}`);
                                        return [false, `Falló la verificación de campo ${tabla.titulo} - ${row.fila[0].label} - ${col.label} ${campo}.`];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return [true, ''];
    },

    ObtenerPorcentaje(value: number | string | null | undefined, isFraction = true): number {
        try {
            if (value !== null && value !== undefined) {
                const numberValue = typeof value === 'number' ? value : parseFloat(value);
                if (!isNaN(numberValue)) {
                    if (isFraction) {
                        return parseFloat((numberValue * 100).toFixed(2));
                    } else {
                        return parseFloat(numberValue.toFixed(2));
                    }
                }
            }
        } catch (error) {
        }
        return 0;
    },

    formatDateFromISOString(dateString: string): string {
        const dateParts = dateString.split(/[-T]/);  // Usa delimitadores `-` y `T        
        // Descomponer la fecha y hora
        const year = dateParts[0];   // Año
        const month = dateParts[1];  // Mes
        const day = dateParts[2];    // Día

        // Formatear en 'DD/MM/AAAA'
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    },

    /**
     * Valida la respuesta de carga de archivo en Bantotal
     * @param response 
     * @param validarDocumentoId utilizar true, solo para la carga de documentos, la actualización no retorna documentoId
     * @returns 
     */
    ValidarCargaDocumento(response: any, validarDocumentoId: boolean = false): string | null {
        try {
            if (response?.status !== 200) {
                return appConfigKey.keyMsjCargaDocumentoError;
            }

            if (!response?.data?.isValid) {
                return appConfigKey.keyMsjCargaDocumentoValidError;
            }

            if (validarDocumentoId && !response?.data?.content?.documentoId) {
                return appConfigKey.keyMsjCargaDocumentoIdError;
            }
        } catch {
            return appConfigKey.keyMsjCargaDocumentoGeneralError;
        }

        return null;
    }

}

export default GeneralHelper;